import Login from "./Login"
import { useToken } from './UseToken'
import Footer from "./Footer";
import Error from "./Error";

import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import blankinfo from './RecordsCreate.json';
import blankinfoES from './RecordsCreateES.json';
import { useTranslation } from 'react-i18next';

function CreateRecord() {
  const { t } = useTranslation();
  const { i18n } = useTranslation(); 
  const getInitialRecords = () => i18n.language.startsWith('es') ? blankinfoES : blankinfo;

  const [records, setRecords] = useState([]);
  const { token, setToken } = useToken()
  const [editableRecords, setEditableRecords] = useState(getInitialRecords());


  if (!token) {
    return <Login setToken={setToken} />
  }

  const handleInputChange = (section, field, value, index = null) => {

    if (index !== null) {
      setEditableRecords(prevRecords => {
        const updatedSection = [...prevRecords.info[section]];
        updatedSection[index] = {
          ...updatedSection[index],
          [field]: value
        };

        return {
          ...prevRecords,
          info: {
            ...prevRecords.info,
            [section]: updatedSection
          },
        };
      });
    }
    else if (index == null && value == null) {
      setEditableRecords(prevRecords => ({
        ...prevRecords,
        info: {
          ...prevRecords.info,
          [section]: field,
        },
      }));
    }
    else {
      setEditableRecords(prevRecords => ({
        ...prevRecords,
        info: {
          ...prevRecords.info,
          [section]: {
            ...prevRecords.info[section],
            [field]: value,
          },
        },
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    editableRecords.records = editableRecords.info

    try {
      const response = await fetch(process.env.REACT_APP_BACKEND + '/records/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token,
        },
        body: JSON.stringify(editableRecords),
      });

      if (!response.ok) {
        throw new Error('Error creating records');
      }
      toast.success('Records Created', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
    } catch (error) {
      toast.error('This Username already exists', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })
      console.error('Error updating records:', error);
    }
  };

  if (!editableRecords || !editableRecords.info) {
    return <Error />;
  }


  return (
    <div >
      <main>
        <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ minWidth: "120px", maxWidth: "20%" }}>
          <Link to="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          </Link>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <Link to="/app/dashboard" class="nav-link active" aria-current="page">
                {t('records.barside.dashboard')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/create" class="nav-link active" aria-current="page">
                {t('records.barside.create')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/records" class="nav-link active" aria-current="page">
                {t('records.barside.records')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/delete" class="nav-link active" aria-current="page">
                {t('records.barside.delete')}
              </Link>
            </li>
          </ul>
        </div>

        <div className="container" style={{ "overflow-y": "auto" }}>
          <div className=" text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
            <h1 className="h2">{t('records.create.s1title')}</h1>
          </div>

          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" data-bs-toggle="tab" href="#simplified" aria-selected="true" role="tab">{t('records.create.s2title0')}</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" data-bs-toggle="tab" href="#complete" aria-selected="false" role="tab" tabindex="-1">{t('records.create.s2title1')}</a>
            </li>
          </ul>
          <div id="myTabContent" class="tab-content">
            <div class="tab-pane fade active show" id="simplified" role="tabpanel">
              <br />

            <div class="container ">
                <form onSubmit={handleSubmit}>
                  <h5>{t('records.create.s2title01')}</h5>
                  <input class="form-control" value={editableRecords.info['username'] || ''} onChange={(e) => handleInputChange('username', e.target.value)} /><br />

                  {t('json.spi.l1txt')}<input class="form-control" type="text" value={editableRecords.info['Personal Information']['Full Name']} onChange={(e) => handleInputChange('Personal Information', 'Full Name', e.target.value)} /><br />
                  {t('json.spi.l5txt')}<input class="form-control" type="text" value={editableRecords.info['Personal Information']['Address']} onChange={(e) => handleInputChange('Personal Information', 'Address', e.target.value)} /><br />
                  {t('json.spi.l7txt')}<input class="form-control" type="tel" value={editableRecords.info['Personal Information']['Phone Number']} onChange={(e) => handleInputChange('Personal Information', 'Phone Number', e.target.value)} /><br />

                  {editableRecords.info['Emergency Contact'].map((contact, index) => (
                    <div key={index}>
                      <strong>{t('json.sec.header')} {index + 1}</strong> <br />
                      {t('json.sec.l1txt')}<input class="form-control" type="text" value={contact.Name} onChange={(e) => handleInputChange('Emergency Contact', 'Name', e.target.value, index)} /><br />
                      {t('json.sec.l2txt')}<input class="form-control" type="text" value={contact.Relationship} onChange={(e) => handleInputChange('Emergency Contact', 'Relationship', e.target.value, index)} /><br />
                      {t('json.sec.l3txt')}<input class="form-control" type="tel" value={contact.Phone} onChange={(e) => handleInputChange('Emergency Contact', 'Phone', e.target.value, index)} /><br />
                    </div>
                  ))}

                  {t('json.smii.l1txt')}<input class="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Provider'] || ''} onChange={(e) => handleInputChange('Medical Insurance Information', 'Provider', e.target.value)} /><br />
                  {t('json.smii.l2txt')}<input class="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Policy Number'] || ''} onChange={(e) => handleInputChange('Medical Insurance Information', 'Policy Number', e.target.value)} /><br />

                  <br />
                  <br />
                  <button type="submit" className="btn btn-primary">{t('records.create.s1btn')}</button>
                  <ToastContainer />
                </form>
              </div>

            </div>
            <div class="tab-pane fade" id="complete" role="tabpanel">
              <br />

              <div class="container ">
                <form onSubmit={handleSubmit}>
                  <h5>{t('json.sun.title')}</h5>
                  <input class="form-control" value={editableRecords.info['username'] || ''} onChange={(e) => handleInputChange('username', e.target.value)} /><br />

                  <h5>{t('json.spi.title')}</h5>
                  {t('json.spi.l1txt')}<input class="form-control" type="text" value={editableRecords.info['Personal Information']['Full Name']} onChange={(e) => handleInputChange('Personal Information', 'Full Name', e.target.value)} /><br />
                  {t('json.spi.l2txt')}<input class="form-control" type="text" value={editableRecords.info['Personal Information']['Date of Birth']} onChange={(e) => handleInputChange('Personal Information', 'Date of Birth', e.target.value)} /><br />
                  {t('json.spi.l3txt')}<input class="form-control" type="text" value={editableRecords.info['Personal Information']['Gender']} onChange={(e) => handleInputChange('Personal Information', 'Gender', e.target.value)} /><br />
                  {t('json.spi.l4txt')}<input class="form-control" type="text" value={editableRecords.info['Personal Information']['Blood Type']} onChange={(e) => handleInputChange('Personal Information', 'Blood Type', e.target.value)} /><br />
                  {t('json.spi.l5txt')}<input class="form-control" type="text" value={editableRecords.info['Personal Information']['Address']} onChange={(e) => handleInputChange('Personal Information', 'Address', e.target.value)} /><br />
                  {t('json.spi.l6txt')}<input class="form-control" type="email" value={editableRecords.info['Personal Information']['Email']} onChange={(e) => handleInputChange('Personal Information', 'Email', e.target.value)} /><br />
                  {t('json.spi.l7txt')}<input class="form-control" type="tel" value={editableRecords.info['Personal Information']['Phone Number']} onChange={(e) => handleInputChange('Personal Information', 'Phone Number', e.target.value)} /><br />
                  {t('json.spi.l8txt')}<input class="form-control" type="tel" value={editableRecords.info['Personal Information']['Photo']} onChange={(e) => handleInputChange('Personal Information', 'Photo', e.target.value)} /><br />
                  {t('json.spi.l9txt')}<input class="form-control" type="tel" value={editableRecords.info['Personal Information']['Ethnicity']} onChange={(e) => handleInputChange('Personal Information', 'Ethnicity', e.target.value)} /><br />
                  {t('json.spi.l10txt')}<input class="form-control" type="tel" value={editableRecords.info['Personal Information']['Preferred Language']} onChange={(e) => handleInputChange('Personal Information', 'Preferred Language', e.target.value)} /><br />
                  {t('json.spi.l11txt')}<input class="form-control" type="tel" value={editableRecords.info['Personal Information']['Nationality']} onChange={(e) => handleInputChange('Personal Information', 'Nationality', e.target.value)} /><br />
                  {t('json.spi.l12txt')}<input class="form-control" type="tel" value={editableRecords.info['Personal Information']['ID TYPE']} onChange={(e) => handleInputChange('Personal Information', 'ID TYPE', e.target.value)} /><br />
                  {t('json.spi.l13txt')}<input class="form-control" type="tel" value={editableRecords.info['Personal Information']['ID']} onChange={(e) => handleInputChange('Personal Information', 'ID', e.target.value)} /><br />

                  <h5>{t('json.sec.title')}</h5>
                  {editableRecords.info['Emergency Contact'].map((contact, index) => (
                    <div key={index}>
                      <strong>{t('json.sec.header')} {index + 1}</strong> <br />
                      {t('json.sec.l1txt')}<input class="form-control" type="text" value={contact.Name} onChange={(e) => handleInputChange('Emergency Contact', 'Name', e.target.value, index)} /><br />
                      {t('json.sec.l2txt')}<input class="form-control" type="text" value={contact.Relationship} onChange={(e) => handleInputChange('Emergency Contact', 'Relationship', e.target.value, index)} /><br />
                      {t('json.sec.l3txt')}<input class="form-control" type="tel" value={contact.Phone} onChange={(e) => handleInputChange('Emergency Contact', 'Phone', e.target.value, index)} /><br />
                    </div>
                  ))}

                  <h5>{t('json.smh.title')}</h5>
                  {t('json.smh.l1txt')}<input class="form-control" type="text" value={editableRecords.info['Medical History']['Known Allergies'] || ''} onChange={(e) => handleInputChange('Medical History', 'Known Allergies', e.target.value)} /><br />
                  {t('json.smh.l2txt')}<input class="form-control" type="text" value={editableRecords.info['Medical History']['Past Surgeries'] || ''} onChange={(e) => handleInputChange('Medical History', 'Past Surgeries', e.target.value)} /><br />
                  {t('json.smh.l3txt')}<input class="form-control" type="text" value={editableRecords.info['Medical History']['Chronic Conditions'] || ''} onChange={(e) => handleInputChange('Medical History', 'Chronic Conditions', e.target.value)} /><br />
                  {t('json.smh.l4txt')}<input class="form-control" type="text" value={editableRecords.info['Medical History']['Family Medical History'] || ''} onChange={(e) => handleInputChange('Medical History', 'Family Medical History', e.target.value)} /><br />
                  {t('json.smh.l5txt')}<input class="form-control" type="text" value={editableRecords.info['Medical History']['Vaccination History'] || ''} onChange={(e) => handleInputChange('Medical History', 'Vaccination History', e.target.value)} /><br />
                  {t('json.smh.l6txt')}<input class="form-control" type="text" value={editableRecords.info['Medical History']['Previous Hospitalizations'] || ''} onChange={(e) => handleInputChange('Medical History', 'Previous Hospitalizations', e.target.value)} /><br />

                  <h5>{t('json.scm.title')}</h5>
                  {editableRecords.info['Current Medications'].map((medication, index) => (
                    <div key={index}>
                      <strong>{t('json.scm.header')} {index + 1}</strong> <br />
                      {t('json.scm.l1txt')}<input class="form-control" type="text" value={medication.Medication || ''} onChange={(e) => handleInputChange('Current Medications', 'Medication', e.target.value, index)} /><br />
                      {t('json.scm.l2txt')}<input class="form-control" type="text" value={medication.Dosage || ''} onChange={(e) => handleInputChange('Current Medications', 'Dosage', e.target.value, index)} /><br />
                      {t('json.scm.l3txt')}<input class="form-control" type="text" value={medication.Frequency || ''} onChange={(e) => handleInputChange('Current Medications', 'Frequency', e.target.value, index)} /><br />
                    </div>
                  ))}

                  <h5>{t('json.scm2.title')}</h5>
                  <input class="form-control" value={editableRecords.info['Contraindicated Medications'] || ''} onChange={(e) => handleInputChange('Contraindicated Medications', e.target.value)} /><br />

                  <h5>{t('json.sppc.title')}</h5>
                  {t('json.sppc.l1txt')}<input class="form-control" type="text" value={editableRecords.info['Personal Physician Contact']['Name'] || ''} onChange={(e) => handleInputChange('Personal Physician Contact', 'Name', e.target.value)} /><br />
                  {t('json.sppc.l2txt')}<input class="form-control" value={editableRecords.info['Personal Physician Contact']['Phone'] || 'N/A'} onChange={(e) => handleInputChange('Personal Physician Contact', 'Phone', e.target.value)} /><br />

                  <h5>{t('json.smii.title')}</h5>
                  {t('json.smii.l1txt')}<input class="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Provider'] || ''} onChange={(e) => handleInputChange('Medical Insurance Information', 'Provider', e.target.value)} /><br />
                  {t('json.smii.l2txt')}<input class="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Policy Number'] || ''} onChange={(e) => handleInputChange('Medical Insurance Information', 'Policy Number', e.target.value)} /><br />
                  {t('json.smii.l3txt')}<input class="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Coverage'] || ''} onChange={(e) => handleInputChange('Medical Insurance Information', 'Coverage', e.target.value)} /><br />
                  {t('json.smii.l4txt')}<input class="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Policy Details'] || ''} onChange={(e) => handleInputChange('Medical Insurance Information', 'Policy Details', e.target.value)} /><br />
                  {t('json.smii.l5txt')}<input class="form-control" type="text" value={editableRecords.info['Medical Insurance Information']['Insurance Contact'] || ''} onChange={(e) => handleInputChange('Medical Insurance Information', 'Insurance Contact', e.target.value)} /><br />

                  <h5>{t('json.sdn.title')}</h5>
                  {t('json.sdn.l1txt')}<input class="form-control" type="text" value={editableRecords.info['Doctor\'s Notes']['Date of Visit'] || ''} onChange={(e) => handleInputChange('Doctor\'s Notes', 'Date of Visit', e.target.value)} /><br />
                  {t('json.sdn.l2txt')}<input class="form-control" type="text" value={editableRecords.info['Doctor\'s Notes']['Reason for Visit'] || ''} onChange={(e) => handleInputChange('Doctor\'s Notes', 'Reason for Visit', e.target.value)} /><br />
                  {t('json.sdn.l3txt')}<input class="form-control" value={editableRecords.info['Doctor\'s Notes']['Recommendations'] || ''} onChange={(e) => handleInputChange('Doctor\'s Notes', 'Recommendations', e.target.value)} /><br />

                  <h5>{t('json.sod.title')}</h5>
                  {t('json.sod.l1txt')}<input class="form-control" type="text" value={editableRecords.info['Organ Donation']['Status'] || ''} onChange={(e) => handleInputChange('Organ Donation', 'Status', e.target.value)} /><br />
                  {t('json.sod.l2txt')}<input class="form-control" value={editableRecords.info['Organ Donation']['Additional information'] || 'N/A'} onChange={(e) => handleInputChange('Organ Donation', 'Additional information', e.target.value)} /><br />


                  <h5>{t('json.sad.title')}</h5>
                  <input class="form-control" value={editableRecords.info['Advance Directives'] || ''} onChange={(e) => handleInputChange('Advance Directives', e.target.value)} /><br />

                  <h5>{t('json.san.title')}</h5>
                  <input class="form-control" value={editableRecords.info['Additional Notes'] || ''} onChange={(e) => handleInputChange('Additional Notes', e.target.value)} /><br />

                  <br />
                  <br />
                  <button type="submit" className="btn btn-primary">{t('records.create.s1btn')}</button>
                  <ToastContainer />
                </form>
              </div>

            </div>
          </div>


        </div>
      </main>
      <Footer />
    </div>
  );
}

export default CreateRecord;

import Login from "./Login"
import { useToken } from './UseToken'
import Footer from "./Footer";
import Error from "./Error";

import { Link } from 'react-router-dom';
import { useState, useEffect } from "react";

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useTranslation } from 'react-i18next';

function DeleteRecord() {
  const { t } = useTranslation();

  const { token, setToken } = useToken()
  const [user_id, setUser_id] = useState('');


  if (!token) {
    return <Login setToken={setToken} />
  }

  const handleInputChange = (e) => {
    setUser_id(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!user_id || user_id.trim() === '') {
      return;
    }

    try {
      const response = await fetch(process.env.REACT_APP_BACKEND + '/records/' + user_id, {
        method: 'DELETE',
        headers: {
          'Authorization': token,
        }
      });
      if (!response.ok) {
        toast.error('This record doesnt exist', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
        return <Error />;
      }

      toast.success('Records Deleted', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      })

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  return (
    <div >
      <main>
        <div class="d-flex flex-column flex-shrink-0 p-3 bg-light" style={{ minWidth: "120px", maxWidth: "20%" }}>
          <Link to="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
          </Link>
          <ul class="nav flex-column mb-auto">
            <li class="nav-item">
              <Link to="/app/dashboard" class="nav-link active" aria-current="page">
                {t('records.barside.dashboard')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/create" class="nav-link active" aria-current="page">
                {t('records.barside.create')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/records" class="nav-link active" aria-current="page">
                {t('records.barside.records')}
              </Link>
            </li>
            <li class="nav-item">
              <Link to="/app/delete" class="nav-link active" aria-current="page">
                {t('records.barside.delete')}
              </Link>
            </li>
          </ul>
        </div>

        <div className="container" style={{"overflow-y": "auto"}}>
          <div className=" text-center justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom ">
            <h1 className="h2">{t('records.delete.s1title')}</h1>
          </div>
          <label class="form-label mt-4" for="inputValid">{t('records.delete.s1txt')}</label>
          <form onSubmit={handleSubmit}>
            <input
              class="form-control"
              type="text"
              style={{ width: '615px' }}
              value={user_id}
              onChange={handleInputChange}
            />
            <em>{t('records.delete.s1txt1')}<br /></em>
            <br />
            <button type="submit" className="btn btn-primary">{t('records.delete.s1btn')}</button>
            <ToastContainer />
          </form>
          <br />


        </div>
      </main>
      <Footer />
    </div>
  );
}

export default DeleteRecord;
